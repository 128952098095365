import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { Button } from "./ui/button";
import {
  BrainCircuit,
  Globe,
  MessageSquare,
  Send,
  Twitter,
  Link,
  DownloadCloud,
  Smartphone,
  Goal,
  MessageCircleMore
} from "lucide-react";

const LandingPage = () => {
  const { t } = useTranslation();
  const [showQRCode, setShowQRCode] = useState(false);

  return (
    <div className="min-h-screen bg-green-50">
      {/* Header */}
      <header className="bg-white shadow-md">
        <div className="container mx-auto px-4 py-6 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-green-600">AI小羊</h1>
          <nav>
            <ul className="flex space-x-4">
              <li>
                <a
                  href="#products"
                  className="text-gray-600 hover:text-green-600"
                >
                  {t("nav.products")}
                </a>
              </li>
              <li>
                <a href="#about" className="text-gray-600 hover:text-green-600">
                  {t("nav.about")}
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  className="text-gray-600 hover:text-green-600"
                >
                  {t("nav.contact")}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {/* Hero Section */}
      <section className="bg-green-600 text-white py-20">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold mb-4">{t("hero.title")}</h2>
          <p className="text-xl mb-8">{t("hero.subtitle")}</p>
          <a
            href="http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=0g-UGdSB0TbdexeJuNuiQs0KBqhzTzZT&authKey=2ttOVfQfU%2Fwyi7hCZDGZdhtnlIYJXFEILtkoo7GNAxaLsiv7RrZnfvGwDdqGUY6%2F&noverify=0&group_code=464834333"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              size="lg"
              className="bg-white text-green-600 hover:bg-green-50"
            >
              {t("hero.cta")}
            </Button>
          </a>
        </div>
      </section>

      {/* Products Section */}
      <section id="products" className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            {t("products.title")}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <a
              href="https://openai-x.net"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center">
                    <MessageSquare className="mr-2" />
                    {t("products.chat")}
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <CardDescription>{t("products.chat.desc")}</CardDescription>
                </CardContent>
              </Card>
            </a>

            <div
              className="relative"
              onMouseEnter={() => setShowQRCode(true)}
              onMouseLeave={() => setShowQRCode(false)}
            >
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center">
                    <MessageCircleMore className="mr-2" />
                    {t("products.openai-chat")}
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <CardDescription>
                    {t("products.openai-chat.desc")}
                  </CardDescription>
                </CardContent>
              </Card>
              {showQRCode && (
                <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 p-2 bg-white rounded-lg shadow-lg">
                  <img
                    src="/openai-chat.jpg"
                    alt="WeChat Mini Program QR Code"
                    className="w-32 h-32"
                  />
                </div>
              )}
            </div>

            <a
              href="https://openai-all.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center">
                    <BrainCircuit className="mr-2" />
                    {t("products.ai-forwarding")}
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <CardDescription>
                    {t("products.ai-forwarding.desc")}
                  </CardDescription>
                </CardContent>
              </Card>
            </a>

            <a
              href="https://mirrorai.chat"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center">
                    <Link className="mr-2" />
                    {t("products.mirror-ai")}
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <CardDescription>
                    {t("products.mirror-ai.desc")}
                  </CardDescription>
                </CardContent>
              </Card>
            </a>

            <a
              href="https://download.openai-x.net"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center">
                    <DownloadCloud className="mr-2" />
                    {t("products.x-download")}
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <CardDescription>
                    {t("products.x-download.desc")}
                  </CardDescription>
                </CardContent>
              </Card>
            </a>

            <a
              href="https://copilot.aisheep.net/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center">
                    <Smartphone className="mr-2" />
                    {t("products.copilot")}
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <CardDescription>
                    {t("products.copilot.desc")}
                  </CardDescription>
                </CardContent>
              </Card>
            </a>

            <a
              href="https://chat.ggemini.pro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center">
                    <Goal className="mr-2" />
                    {t("products.gemini")}
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <CardDescription>{t("products.gemini.desc")}</CardDescription>
                </CardContent>
              </Card>
            </a>

            <Card>
              <CardHeader>
                <CardTitle className="flex items-center">
                  <Globe className="mr-2" />
                  {t("products.browser")}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <CardDescription>{t("products.browser.desc")}</CardDescription>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="bg-green-100 py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-8">
            {t("about.title")}
          </h2>
          <p className="text-center max-w-2xl mx-auto">{t("about.content")}</p>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-20">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-8">{t("contact.title")}</h2>
          <p className="mb-8">{t("contact.content")}</p>
          <a href="mailto:support@aisheep.net">
            <Button
              size="lg"
              className="bg-green-600 text-white hover:bg-green-700"
            >
              {t("contact.cta")}
            </Button>
          </a>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-green-800 text-white py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p>{t("footer.copyright")}</p>
            <div className="flex space-x-4 mt-4 md:mt-0">
              <a
                href="https://x.com/x_aisheep"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-green-300"
              >
                <Twitter size={24} />
                <span className="sr-only">{t("social.twitter")}</span>
              </a>
              <a
                href="https://t.me/aisheep_net"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-green-300"
              >
                <Send size={24} />
                <span className="sr-only">{t("social.telegram")}</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
